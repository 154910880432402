/************** right sidebar **************/
html, body {

    font-family: "Roboto", sans-serif !important;
}

.colorBoxContainer:hover
{
    box-shadow: 1px 2px 4px;
}

.modal-dialog {
    outline: none;
}
.required-field{
    border:1px solid #bf3346;
}
.recent-selected-filter{
    color: #3452E1;
}
.right-sidebar {
    position: fixed;
    width: 420px;
    right: 0;
    top: 0;
    margin-top: 94px;
    height: calc(100% - 128px);
    overflow: auto;
}
.right-sidebar-show {
    transition: all 0.2s;

}
.right-sidebar-hide {
    margin-right: -420px;
    transition: all 0.1s;
}

.content-with-sidebar {
    width: calc(100% - 420px);
    transition: all 0.2s;
}
.content-with-no-sidebar {
    width: 100%;
    transition: all 0.2s;
}

.pointer{
    cursor:pointer;
}

.pointer:hover{
    background-color: rgba(234, 234, 240, 0.83);
}
.inputTransparent{
    background-color: transparent !important;
    margin-top:-5px;
    text-align: left;
    padding: 0 !important;
}
.searchContainer{
    margin-top: 8px;
    border-radius: 5px;
    max-height: 400px;
    overflow: auto;
}
.search-box {
    width: 600px;
    position: absolute;
    border-radius: 5px;
    padding: 8px;
    margin: 8px;
    border:0.1em solid transparent;
}
.search-box.expanded {
    background-color: white;
    border:0.1em solid #e4e4e4;
}

.search-box-container {
    position: relative;
}
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    /*padding-left: 5px;*/
    /*padding-top: 5px;*/
    border-radius: 0.25rem;
    height: auto;
    max-width: 100%;
}
.react-tagsinput span {
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
    margin-right: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    color: white;
    overflow-wrap: break-word;
    max-width: 100%;
}
.react-tagsinput--focused {
    border-color: #546de4;
}

.react-tagsinput-tag {
    background-color: #546de4;
    border-radius: 2px;
    border: 1px solid #546de4;
    color: #dddde3;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    display: inline-list-item;
    /*margin-bottom: 5px;*/
    /*margin-right: 5px;*/
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
    color: white;
    margin-left: 4px;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100%;
    height:auto;
    position: relative;
}

.modal-full-height .modal-content {
    height: 100%;
}

.modal-empty-content .modal-content {
    height: 280px;
    width:280px;
}

.custom-control{
    z-index: 0 !important;
}
.portal .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.portal .modal-dialog {
    position: fixed;
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
}

.portal .modal-content {
    position: absolute;
    top: 18px;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0;
    background-color: transparent !important;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: center;
}

.portal .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    padding: 10px;
    border: 0;
}

.portal .modal-title {
    font-weight: 300;
    font-size: 2em;
    color: #fff;
    line-height: 30px;
}

.portal .modal-body {
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    font-weight: 300;
    overflow: auto;
}

.portal .modal-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    padding: 10px;
    background: #f1f3f5;
}
.modal-backdrop.show.portal {
    opacity: 0.8 !important;
}

.selectList{
    min-height: 40px;
    max-height: 80px;
    height: auto;
}

.noProps{
    text-align:center;
    width:100%;
}

.createMenuShow {
    text-align: center;
    opacity:1;
    height:150px;
    width:200px;
    display:block;
    visibility:visible;
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: visibility 0.2s , opacity 0.3s, bottom 0.2s ease-in-out;
}

.createMenuItem{
    border:0.03em solid rgba(200,200,200,0.3);
    background-color: white;
    margin-bottom: 10px;
    border-radius: 10px;
    color: #1b1e21;
    padding: 10px;
    cursor: pointer;
}
.createMenuItem:hover{
    background-color: #f1f1f1;
}

.createMenuHide {
    opacity:0;
    visibility:hidden;
    bottom:-10px;
}

/************** Survey **************/
.survey-field-box {
    box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 6px -1px;
    transition: all 0.4s;
}

.survey-field-box:hover {
    background-color: rgba(0, 0, 0, 0.02)
}

.survey-field-box:focus-within {
    background-color: rgba(0, 0, 255, 0.019)
}

.survey-dnd-handle {
    opacity: 0.1
}

.survey-field-box:hover .survey-dnd-handle, .survey-field-box:focus-within .survey-dnd-handle {
    opacity: 1
}

.survey-field-add-new {
    opacity: 0.6;
    border: 2px dashed #555b6d;
    cursor: pointer;
    background-color: rgba(255,255,255,0.2) !important;
    box-shadow: none !important;
}

.survey-field-add-new:hover {
    opacity: 1
}

.c-item-add-new {
    position: relative;
    opacity: 0.6;
    border: 2px dashed #555b6d;
    cursor: pointer;
    background-color: rgba(255,255,255,0.2) !important;
    box-shadow: none !important;
    font-size: 14px;
    min-height: 215px;
}

.c-item-add-new i {
    font-size: 36px;
}

.c-item-add-new:hover {
    opacity: 1
}

.c-item-error{
    background-color: #e8c7c3 !important;
}

.survey-nav .nav {
    display: block;
}

.survey-nav .nav-tabs > li {
    float:none;
    display:inline-block;
    zoom:1;
}

.survey-nav .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background: none;
}

.link-disabled {
    opacity: 0.2;
    cursor: default !important;
}

.survey-nav .nav-tabs {
    text-align:center;
}

.c-item {
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;
    min-height: 215px;
    margin-bottom: 0;
    border-radius: 6px;
    overflow: hidden;
    min-width: 280px;
}

.c-item-wrapper {
    padding: 8px;
}

.c-item:hover {
    border: 1px solid rgba(0,0,255,0.2);
    min-height: 215px;
}

.c-story-item {
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;
    min-height: 420px;
    margin-bottom: 0;
    border-radius: 6px;
    overflow: hidden;
    min-width: 280px;
}

.c-story-item-wrapper {
    padding: 8px;
}

.c-story-item:hover {
    border: 1px solid rgba(0,0,255,0.2);
    min-height: 420px;
}

.survey-field-view {
    padding: 8px;
    border-radius: 8px;
    transition: background-color 0.4s;
    background-color: white;
}
.survey-field-view:hover {
    background-color: #f3f3f6 !important;
}

.survey-view-page {
    position: relative;
    padding-top: 30px;
    background-color: white;
    box-shadow: 5px 4px 6px -2px rgba(0,0,0,0.1);
    padding-bottom: 30px;
    margin-top: 24px;
    margin-bottom: 32px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
}

.responses-wrapper {
    background-color: white;
    padding: 16px;
    box-shadow: 5px 4px 6px -2px rgba(0,0,0,0.1);
    /*margin-top: 24px;*/
    margin-bottom: 32px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
}

.custom-loading-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.5;
    z-index: 1;
}

.survey-number-input-response {
    width: 90px;
    height: 34px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 0;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.survey-pagination-item {
    line-height: 36px;
    text-align: center;
    min-width: 15px;
    height: 34px;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 500;
    font-size: 16px;
}

.survey-submit-message {
    text-align: center;
}

.c-item-list {
    cursor: pointer;
}

.c-item-list:hover, .table-zebra tr.c-item-list:hover {
    background-color: #f1f1f1;
}

.responses-navigation .pagination, .listing-navigation .pagination {
    float: right;
}

.table-zebra tr:nth-child(even) {
    background-color: #f9f9f9;
}

.react-multi-email {
    max-height: 180px;
    overflow-y: auto;
}

.multiple-choice-grid .form-check-inline {
    margin-right: -5px !important;
}

.card-answer {
    background-color: #f9f9f9;
    margin-top: 16px;
    /*background-color: white;*/
    border-radius: 4px;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}

.card-answer:hover {
    background: rgba(0, 0, 0, 0.03);
}

.survey-user-details {
    border: 1px solid #cdcdcd;
    padding: 20px;
    margin-bottom: 20px;
}

.drop-file-box {
    border-radius: 4px;
    min-height: 200px;
    border: 1px dashed #cdcdcd;
    padding: 20px;
}

.drop-link {
    cursor: pointer;
    font-weight: bold;
}

.drop-link:hover {
    color: #1f3dd0;
}

.font-size-42 {
    font-size: 42px !important;
}

.active-drop {
    background-color: rgba(0,0,255,0.1);
}

.section-box {
    border: 1px solid rgba(0,0,0,0.2);
}

/************** Windows scrollbar **************/
html,body {
  overflow: auto;
}


/* Story */


/*Story Type SVG Colors*/
.st0{fill:#707C82;opacity:1}
.st1{fill:#AEB5B7;opacity:1}
.st2{opacity:1;fill:#AEB5B7;}

.layout-template-column{
    border-radius: 4px;
    height:90px;
    border:1px solid white;
    background-color: #AEB5B7;
    text-align: center;
    color:white;
    font-weight: 400;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    user-select: none;
}


.storyBox{
    display: flex;
    /*background-color: #434141;*/
    width:240px;
    height: 200px;
    /*box-shadow: -1px 4px 20px 0 #c5c5c5;*/
    background-size: cover;
    /*border-radius: 15px;*/
    /*margin-right: 10px;*/
    /*margin-bottom: 10px;*/
    box-sizing: border-box;
    /*border: 3px solid rgba(0,0,0,0);*/
}

.storyBox > * {
    box-sizing: border-box;
}

.storyBox:hover, .storyBox-selected {
    /*border: 3px solid rgba(0,0,190,0.7);*/
    box-shadow: 0 0 0 0.15rem rgb(106 179 243 / 50%);
}

.no-cursor {
    cursor: default;
    border: none;
    box-shadow: none !important;

}

.no-cursor:hover {
    box-shadow: none !important;
    border: none;
}

.no-cursor.storyBox {
    box-shadow: none;
}

.storyPreviewIcon{
    text-decoration: none;
    color:#fff;
}

.storyBoxNew{
    display: flex;
    /*background-color: #434141;*/
    width:240px;
    height: 200px;
    border-radius: 15px;
    border:1px dashed #6d6d6d;
    justify-content: center;
    justify-items: center;
    align-items: center;
    cursor:pointer;
}

.storyTitle{
    color:#fff;
    font-size: 20px;
    text-shadow: 0 2px 3px black;
}

.storyTools{
    left: 35px;
    margin: 0 auto;
    bottom: 20px;
    width: 100%;
    height:40px;
    position: absolute;
    text-align: center;
    color:#fff;
    font-size: 35px;
    text-shadow: 0 2px 3px black;
}
.slideshow-editor-add-slide{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    width:160px;
    height: 80px;
    padding:10px;
    border:1px dashed #5d5d5d;
    margin-bottom: 10px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
    float: left;
}
.slideshow-editor-slide
{
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    margin: 4px;
    width:160px;
    height: 80px;
    padding:10px;
    background-color: #fcfcff;
    border:1px solid #dedede;
    margin-bottom: 10px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
}
.story-tool-icon{
    font-size:25px;
    color: #4c4c4c;
    cursor: pointer;
}

.story-toolbar{
    left: calc(100vw - 67em);
    display: flex;
    position: absolute;
    align-items: center;
    align-content: flex-end;
    justify-content: flex-end;
    justify-items: center;
    width: 200px;
}

.slideshow-editor-slide:hover{
    background-color: #f6f6f6;
}
.slideshow-editor-slide-layoutName{
    font-size: 14px;
    font-weight: 500;
    display: contents;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 80px;
    height: auto;
    color: #000;
    font-family: "Roboto";
}

.slideshow-editor-slide-actions{
    float:right;
}
.slideshow-editor-slide-selected
{
    background-color: #ffffff;
    border-color: #1f3dd0;
    max-height: 80px;
    overflow: hidden;
}

.slider-editor-projector-null-element{
    border: 1px solid #556ee521;
    border-radius: 5px;
    height: 298px;
    background-color: white;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    display: flex;
    font-size: 12px;
    color:#101010;
    user-select: none;
    cursor: pointer;
}
.slider-editor-projector-null-element:hover {
background-color: #eef5f6;
}
.screenOption p {
    font-size: 12px;
}

.screenOptionSelected p {
    font-size: 12px;
}

.screenOption {
    border:1px solid #cecece;
    max-width: 80px;
    margin-right: 5px;
    padding:6px;
    font-weight: 500;
    border-radius: 6px;
    text-align: center;
    height: 50px;
    user-select: none;
    cursor: pointer;
}

.screenOptionSelected {
    border:1px solid #ffffff;
    max-width: 80px;
    margin-right: 5px;
    padding:6px;
    font-weight: 500;
    border-radius: 6px;
    text-align: center;
    height: 50px;
    user-select: none;
    cursor: pointer;
    color:white;
    background-color: #556ee6;
}

.handleElement{
    padding:10px;
    width: 100%;
    height: 100%;
    min-width: 20px;
    min-height: 20px;
    position: absolute;
    user-select: none;
    cursor: pointer;
}

.imageElement{
    font-size:60px;
}

.slidePickColor{
    width: 25px;
    height: 25px;
    border: 1px solid black;
    border-radius: 3px;
    cursor:pointer;
    display: flex;
}

.slideBackgroundImage{
    width:80px;
    height: 80px;
    border:1px dashed #d0cdcd;
    cursor:pointer;
}

.slideColorIndicator{
    width: 12px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.textAlignmentButtonSelected {
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    width: 25px;
    height: 25px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #556ee6;
    color: white;
    cursor: pointer;
}

.textAlignmentButton {
    border: 1px solid #556ee6;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #101010;
    cursor: pointer;
}

.textDecorationButtonSelected {
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    width: 25px;
    height: 25px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #556ee6;
    color: white;
    cursor: pointer;
}

.textDecorationButton {
    border: 1px solid #556ee6;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #101010;
    cursor: pointer;
}

.nonDraggableElement{
    -webkit-user-drag: none;
    user-select: none;
    -webkit-user-select: none;
}
.nonDraggableImg{
    -webkit-user-drag: none;
    user-select: none;
    -webkit-user-select: none;
}

.timeLineContainer{
    max-height: calc(100vh - 35em);
    padding-top:25px;
    overflow: auto;
}

.elementAnimationRow{
    padding:10px;
    margin-bottom:2px;
    width:100%;
    border:1px solid #cecece;
    border-radius: 4px;
}

.projectionWrapper {
    top: 0;
    height: calc(100vh - 140px - 160px - 25px);
    width: calc(100vw - 250px - 380px - 50px);
    z-index: 2;
    box-shadow: 0 -1px 15px 0 rgba(0,0,0,0.06);
    position:relative;
}

.projectionArea{
    box-sizing: border-box;
    /*border: 1px solid #000;*/
    position: relative;
    left: 50%;
    top: 50%;
    overflow: hidden;
}

.slidesContainer{
    width: calc(100vw - 50em);
}

.previewBox {
    border: 1px solid #cdcdcd;
    padding: 16px;
    background-color: white;
    border-radius: 6px;
}
.btn-stories-filter{
    background-color: transparent;
    border: none;
    color: #74788d !important;
    font-weight: 500;
    padding: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    justify-items: center;
    height: 35px;
}

.btn-stories-filter:hover {
    background-color: transparent !important;
    border: none !important;
    color: #74788d !important;
}

.slideTemplate{
    width:200px;
    height: 200px;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin:2px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.slideTemplateSelected{
    width: 200px;
    height: 200px;
    border: 1px solid #1f3dd0;
    border-radius: 5px;
    margin:2px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.listTableRow {
    cursor:pointer;
}

.listTableRow:hover{
    cursor:pointer;
    background-color: #f1f1f1;
    border: 1px solid #1f3dd0;
    border-radius: 5px;
}

.listTableRowNonEditable{
    cursor: not-allowed;
}

.delete-attribute{
    color: #ffffff;
    width: 25px;
    height: 25px;
    background: #314580;
    text-align: center;
    border-radius: 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    cursor:pointer;
}

.delete-attribute:hover {
    background: #465c9f;
}

.attribute-item{
    align-content: center;
    background-color: #ebf0ff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 2px;
    color: #656565;
}

.bg-border-primary{
    box-shadow: 0px 0px 0px 1px #556ee6 inset;

}
.sweet-alert{ animation: none !important;}
